const moment = require('moment');
export default ({fetchUrl = '', showFilter = false}) => ({
    _: require('lodash'),
    expanded: false,
    data: {},
    tableData: [],
    expandedRows: [],
    filter: {},
    showFilter: showFilter,
    expandedFilterCategory: [],
    selectedFilters: {},
    loading: true,
    search: '',
    error: false,
    errorMessage: '',
    async init() {
        if (fetchUrl !== '') {
            const data = await this.fetchData(fetchUrl);
            if (data) {
                this.data = Object.values(data.orders);
                this.tableData = this.data;
                if (showFilter) {
                    this.filter = data.filter;
                }
            }
            this.loading = false;
        } else {
            this.loading = false;
        }
    },
    setTableData(data) {
        this.tableData = data;
    },
    async fetchData(fetchUrl) {
        return fetch(fetchUrl)
            .then((response) => {
                if (response.ok) return response.json();

                this.error = true;
                switch (response.status) {
                    case 403 || 404:
                        this.errorMessage = 'U bent niet bevoegd om deze data te bekijken.';
                        break;
                    case 500:
                        this.errorMessage = 'Er is een interne fout opgetreden. Neem contact op met ICT.';
                        break;
                    default:
                        this.errorMessage = 'Er is een fout opgetreden.';
                        break;
                }
                this.loading = false;
                return false;
            })
    },
    addFilterValue(filterTitle, value) {
        if (!Object.hasOwn(this.selectedFilters, filterTitle)) {
            this.selectedFilters[filterTitle] = [value];
        } else if (this.$el.checked) {
            this.selectedFilters[filterTitle].push(value)
        } else {
            const index = this.selectedFilters[filterTitle].indexOf(value);
            this.selectedFilters[filterTitle].splice(index, 1);
        }
        this.filterData(this.getSelectedFilterValues());
    },
    getSelectedFilterValues() {
        return Object.values(this.selectedFilters).flat();
    },
    searchData() {
        const searchValue = this.$el.value;
        if (searchValue !== '') {
            this.tableData = this.data;
            this.filterData(searchValue)
        } else {
            this.filterData(searchValue)
        }
    },
    filterData(values) {
        if (values.length === 0) {
            this.tableData = this.data;
            return
        }
        if (typeof values === 'string') {
            this.tableData = this.data.filter((order, i) => {
                let hasValue = false;
                Object.values(order).forEach((value) => {
                    if (typeof value === 'string' && value.toLowerCase().includes(values.toLowerCase())) {
                        hasValue = true;
                    }
                });
                return hasValue;
            });
        } else {
            this.tableData = this.data.filter((e, i) => {
                const intersect = this._.intersection(Object.values(e), values);
                return intersect.length !== 0;
            });
        }
    },
    expandFilterCategory(filterTitle) {
        if (this.expandedFilterCategory.includes(filterTitle)) {
            const index = this.expandedFilterCategory.indexOf(filterTitle);
            if (index !== -1) {
                this.expandedFilterCategory.splice(index, 1);
            }
        } else {
            this.expandedFilterCategory.push(filterTitle);
        }
    },
    sortByColumn($event) {
        if (this.sortBy !== '' && this.sortBy !== $event.target.innerText) {
            for (const child of $event.target.parentNode.children) {
                child.classList.remove('sorted');
                child.classList.remove('sorted--desc');
                child.classList.remove('sorted--asc');
            }
        }
        if (this.sortBy === $event.target.innerText) {
            if (this.sortAsc) {
                this.sortBy = "";
                this.sortAsc = false;
            } else {
                this.sortAsc = !this.sortAsc;
            }
        } else {
            this.sortBy = $event.target.innerText;
        }
        let rows = this.getTableRows()
            .sort(
                this.sortCallback(
                    Array.from($event.target.parentNode.children).indexOf(
                        $event.target
                    )
                )
            )
            .forEach((tr) => {
                this.$refs.tbody.appendChild(tr);
            });
        if (this.sortBy !== '') {
            $event.target.classList.add('sorted');
        } else {
            $event.target.classList.remove('sorted');
            $event.target.classList.remove('sorted--desc');
            $event.target.classList.remove('sorted--asc');
        }
        if (this.sortAsc) {
            $event.target.classList.add('sorted--asc');
            $event.target.classList.remove('sorted--desc');
        } else {
            $event.target.classList.add('sorted--desc');
            $event.target.classList.remove('sorted--asc');
        }
    },
    getTableRows() {
        return Array.from(this.$refs.tbody.querySelectorAll("tr"));
    },
    getCellValue(row, index) {
        return row.children[index].innerText;
    },
    sortCallback(index) {
        return (a, b) =>
            ((row1, row2) => {
                return row1 !== "" &&
                row2 !== "" &&
                !isNaN(row1) &&
                !isNaN(row2)
                    ? row1 - row2
                    : row1.toString().localeCompare(row2);
            })(
                this.getCellValue(this.sortAsc ? a : b, index),
                this.getCellValue(this.sortAsc ? b : a, index)
            );
    },
    closeFilter() {
        const $filterWrapper = this.$refs.filterWrapper;
        if ($filterWrapper.classList.contains('active')) {
            $filterWrapper.classList.remove('active')
        }
    },
    toggleFilter() {
        this.$refs.filterWrapper.classList.toggle('active')
    },
    resetFilter() {
        this.selectedFilters = {};
        this.filterData([])
    },
    hasValue(filterTitle, value) {
        return (this.selectedFilters[filterTitle] && this.selectedFilters[filterTitle].includes(value))
    },
    searchTable() {
        const filter = this.search.toUpperCase(),
            rows = this.$refs.tbody.getElementsByTagName("tr");

        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].getElementsByTagName("td");
            let rowShouldBeVisible = false;

            for (let j = 0; j < cells.length; j++) {
                const cell = cells[j];
                if (cell) {
                    const textValue = cell.textContent || cell.innerText;
                    if (textValue.toUpperCase().indexOf(filter) > -1) {
                        rowShouldBeVisible = true;
                        break;
                    }
                }
            }

            if (rowShouldBeVisible) {
                rows[i].style.display = "";
            } else {
                rows[i].style.display = "none";
            }
        }
    },
    toggleRow(i) {
        if (this.expandedRows.includes(i)) {
            this.expandedRows = this.expandedRows.filter(e => e !== i);
        } else {
            this.expandedRows.push(i);
        }
    },
    rowIsExpanded(i) {
        return this.expandedRows.includes(i);
    },
    parseDate(utcDateString) {
        return moment.utc(utcDateString).format("DD-MM-YYYY");
    }
})