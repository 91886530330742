export function multiSelect({options, name}) {
    return {
        options: options,
        fieldName: name,
        dropdownOpen: false,
        selected: [],
        searchString: '',
        isResizing: false,
        shouldShowAbove: false,
        init() {
            options.forEach((option) => {
                if (option.selected) {
                    this.selected.push(option.value);
                }
            })
        },
        getSelectedOptions() {
            return this.options.filter(option => {
                return this.selected.some(e => e == option.value);
            });
        },
        removeSelection(index) {
            this.selected.splice(index, 1);
        },
        getDropdownOptions() {
            return this.options.filter(option =>
                option.label.toLowerCase().includes(this.searchString.toLowerCase())
            );
        },
        toggleSelection(value) {
            if (!this.isSelected(value)) {
                this.selected.push(value);
            } else {
                this.selected = this.selected.filter(e => e !== value);
            }
        },
        isSelected(value) {
            return this.selected.includes(value);
        },
        focus() {
            this.dropdownOpen = !this.dropdownOpen;
            if (this.dropdownOpen) {
                this.$refs.searchField.focus();
            } else {
                this.$refs.searchField.blur();
            }
        },
        hideDropdown() {
            this.dropdownOpen = false;
        },
        preventCloseOnResize() {
            setTimeout(() => this.isResizing = false, 50);
        },
        allSelected() {
            return (this.selected.length === this.options.length);
        },
        toggleAll() {
            if (this.selected.length === this.options.length) {
                this.selected = [];
            } else {
                this.selected = this.options.map(e => e.value);
            }
        },
        searchFieldChange() {
            const padding = 2; // Adjust for padding within the textarea, if any
            const minWidth = 5; // Minimum width of the textarea in ch units to ensure it's not too small when empty
            const maxWidth = 30; // Maximum width to prevent the textarea from becoming too wide
            const additionalSpace = 1.5; // Additional space to account for letter spacing and padding

            let newWidth = Math.max(Math.max(this.searchString.length + additionalSpace + padding, minWidth), maxWidth);

            this.$refs.searchField.style.width = `${Math.round(newWidth)}ch`;
        }
    }
}