const moment = require('moment'),
    _ = require('lodash');

export default ({organizations}) => ({
    sortBy: "",
    fetchingInspectionReports: false,
    sortAsc: false,
    loadingAppliances: false,
    organization: false,
    location: null,
    department: null,
    applianceGroup: null,
    progressIndicator: '',
    reportsIndicator: '',
    activity: '',
    organizations: organizations,
    locations: {},
    departments: {},
    appliancesGroups: {},
    appliances: false,
    applianceActivities: [],
    selectedAppliances: [],
    inspectionReports: [],
    applianceCounter: 0,
    reportsCounter: 0,
    async init() {
        this.applianceActivities = await this.getActivityTranslations();
        this.$refs.form.addEventListener("submit", (e) => {
            e.preventDefault()
        });
    },
    async getActivityTranslations() {
        return await this.fetch("/ajax/appliance/getNwActvityTranslations");
    },
    async getLocations() {
        const organizationId = this.organization;
        if (!organizationId) {
            return []
        }

        if (!this.locations.hasOwnProperty(organizationId)) {
            this.locations[organizationId] = await this.fetch('/ajax/locations/getRevenuLocations/' + organizationId);
        }
        return this.locations[organizationId];
    },
    async fetchLocations(organizationId) {
        return await this.fetch('/ajax/locations/getRevenuLocations/' + organizationId);
    },
    async getDepartments() {
        const locationId = this.location;
        if (!locationId) {
            return []
        }

        if (!this.departments.hasOwnProperty(locationId)) {
            this.departments[locationId] = await this.fetchDepartments(locationId);
        }
        return this.departments[locationId];
    },
    async fetchDepartments(locationId) {
        return await this.fetch('/ajax/departments/getDepartments/' + locationId);
    },
    async getApplianceGroups() {
        const organizationId = this.organization;
        if (!organizationId) {
            return []
        }

        if (!this.appliancesGroups.hasOwnProperty(organizationId)) {
            this.appliancesGroups[organizationId] = await this.fetchApplianceGroups(organizationId);
        }
        return this.appliancesGroups[organizationId];
    },
    async fetchApplianceGroups(organizationId) {
        const result = await this.fetch(`/ajax/appliance/getApplianceGroupsInScope/${organizationId}/INSPECTION`);
        return result.sort((a, b) => a.name.localeCompare(b.name))
    },
    async searchAppliances() {
        if (!this.$refs.form.checkValidity()) {
            return false;
        }

        const dateFrom = this.$refs.dateFrom.querySelector('input').value ? moment(this.$refs.dateFrom.querySelector('input').value, "DD-MM-YYYY") : null,
            dateTo = this.$refs.dateTo.querySelector('input').value ? moment(this.$refs.dateTo.querySelector('input').value, "DD-MM-YYYY") : moment();


        this.loadingAppliances = true;
        this.appliances = [];
        this.selectedAppliances = [];


        let appliances = await this.fetch("/ajax/appliance/getAppliances", {
            organization_id: this.organization,
            location_id: this.location,
            department_id: this.department,
            appliance_group_id: this.applianceGroup,
            status: this.activity,
        });

        // If a date is given, filter out the appliances outside the date range
        if (dateFrom !== null) {
            appliances = appliances.filter((appliance) => {
                return moment(appliance.lastinspectiondate).isBetween(dateFrom, dateTo);
            });
        }
        this.appliances = appliances;
        this.loadingAppliances = false;
    },
    async getApplianceInspections() {
        const reports = {},
            selectedAppliances = this.appliances.filter(e => this.selectedAppliances.includes(e.serviceitem_id)),
            dateFrom = this.$refs.dateFrom.querySelector('input').value ? moment(this.$refs.dateFrom.querySelector('input').value, "DD-MM-YYYY") : null,
            dateTo = this.$refs.dateTo.querySelector('input').value ? moment(this.$refs.dateTo.querySelector('input').value, "DD-MM-YYYY") : moment();

        this.applianceCounter = 0;
        this.reportsCounter = 0;
        this.inspectionReports = [];
        this.fetchingInspectionReports = true;

        this.$refs.dialog.showModal({focus: false});

        const fetchInspectionReports = selectedAppliances.map(async (appliance) => {
            // Fetch and possibly filter the inspection reports
            let inspectionReports = await this.fetchInspectionReportsFromRevenu(appliance.serviceitem_id);

            // Filter out inspection reports with wrong dates
            if (dateFrom !== null) {
                inspectionReports = inspectionReports.filter((report) => {
                    return moment(report.instantCompleted).isBetween(dateFrom, dateTo);
                })
            }

            if (inspectionReports.length !== 0) {
                appliance.inspectionReports = inspectionReports;
                this.inspectionReports.push(appliance);
                this.reportsCounter += inspectionReports.length;
            }
            this.applianceCounter++;
        });


        Promise.all(fetchInspectionReports).then(() => {
            this.fetchingInspectionReports = false;
        });
    },
    async fetchInspectionReportsFromRevenu(serviceitemId) {
        return await this.fetch("/ajax/appliance/getInspectionReports/" + serviceitemId);
    },
    resetSearchFilters() {
        this.location = null;
        this.department = null;
    },
    selectAll() {
        if (this.$el.checked) {
            this.selectedAppliances = this.appliances.map(e => e.serviceitem_id);
        } else {
            this.selectedAppliances = [];
        }
    },
    sortByColumn($event) {
        if (this.sortBy !== '' && this.sortBy !== $event.target.innerText) {
            for (const child of $event.target.parentNode.children) {
                child.classList.remove('sorted');
                child.classList.remove('sorted--desc');
                child.classList.remove('sorted--asc');
            }
        }

        if (this.sortBy === $event.target.innerText) {
            if (this.sortAsc) {
                this.sortBy = "";
                this.sortAsc = false;
            } else {
                this.sortAsc = !this.sortAsc;
            }
        } else {
            this.sortBy = $event.target.innerText;
        }

        let rows = this.getTableRows()
            .sort(
                this.sortCallback(
                    Array.from($event.target.parentNode.children).indexOf(
                        $event.target
                    )
                )
            )
            .forEach((tr) => {
                this.$refs.tbody.appendChild(tr);
            });

        if (this.sortBy !== '') {
            $event.target.classList.add('sorted');
        } else {
            $event.target.classList.remove('sorted');
            $event.target.classList.remove('sorted--desc');
            $event.target.classList.remove('sorted--asc');
        }
        if (this.sortAsc) {
            $event.target.classList.add('sorted--asc');
            $event.target.classList.remove('sorted--desc');
        } else {
            $event.target.classList.add('sorted--desc');
            $event.target.classList.remove('sorted--asc');
        }
    },
    getTableRows() {
        return Array.from(this.$refs.tbody.querySelectorAll("tr"));
    },
    getCellValue(row, index) {
        return row.children[index].innerText;
    },
    sortCallback(index) {
        return (a, b) =>
            ((row1, row2) => {
                return row1 !== "" &&
                row2 !== "" &&
                !isNaN(row1) &&
                !isNaN(row2)
                    ? row1 - row2
                    : row1.toString().localeCompare(row2);
            })(
                this.getCellValue(this.sortAsc ? a : b, index),
                this.getCellValue(this.sortAsc ? b : a, index)
            );
    },
    async getColumnValues() {
        await this.$nextTick();

        const cellIndex = this.$el.closest('th').cellIndex,
            rows = Array.from(this.$refs.tbody.getElementsByTagName('tr')),
            values = [];

        rows.forEach(e => {
            const value = e.children[cellIndex].innerText;
            if (!values.includes(value)) {
                values.push(value)
            }
        });
        return values;
    },
    async fetch(resource, body = {}) {
        let options = {};
        if (Object.keys(body).length !== 0) {
            options.method = 'POST';
            options.headers = {
                'Content-Type': 'application/json',
            };
            options.body = JSON.stringify(body);
        }
        const response = await fetch(resource, options);

        if (!response.ok) {
            let message = '';
            switch (response.status) {
                case 403:
                    message = 'U bent niet bevoegd om deze data op te halen.';
                    break;
                case 400:
                    message = 'Uw verzoek kan niet verwerkt worden.';
                    break;
                default:
                    message = 'Er is een onbekende fout opgetreden.';
                    break;
            }
            this.$dispatch('add-flash-message', {
                message: message,
                type: 'error',
                permanent: true
            });
            throw new Error('Fetch error');
        }

        return response.json();
    },
});