//TODO would be nice to load all the svgs from the spritesheet folder at once
import "../../_img/spritesheet/mutate.svg";
import "../../_img/spritesheet/return.svg";
import "../../_img/spritesheet/broom.svg";
import "../../_img/spritesheet/clock.svg";
import "../../_img/spritesheet/deliver.svg";
import "../../_img/spritesheet/dispose.svg";
import "../../_img/spritesheet/migrate.svg";
import "../../_img/spritesheet/register.svg";
import "../../_img/spritesheet/trash-can.svg";
import "../../_img/spritesheet/cross.svg";
import "../../_img/spritesheet/repair.svg";
import "../../_img/spritesheet/arrow.svg";
import "../../_img/spritesheet/chevron.svg";
import "../../_img/spritesheet/color-wheel.svg";
import "../../_img/spritesheet/rotate.svg";