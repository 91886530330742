import {UrlParamManager} from '../../_utils.js';

export function tabs({activeTab = ''}) {
    return {
        activeTab: Alpine.$persist(activeTab).as('activeTab' + window.location.pathname),
        urlParamManager: UrlParamManager.getInstance(),
        init() {
            if (this.urlParamManager.getParam('tab')) {
                this.activeTab = this.urlParamManager.getParam('tab');
            }

            if (this.$refs.tabsMenu) {
                this.$refs.tabsMenu
                    .querySelectorAll('[data-tab]')
                    .forEach((e, i) => {
                        const targetTab = e.getAttribute('data-tab');
                        const button = e.querySelector('button');

                        if (button === null) {
                            console.warn(`Alpine Warning: tab menu is missing a button element`);
                            return;
                        }

                        if (targetTab === this.activeTab || this.activeTab === '' && i === 0) {
                            e.classList.add('active');
                            this.activeTab = targetTab;
                        }

                        const Alpine = this;
                        button.addEventListener('click', function (e) {
                            Alpine.switchTab(e.target.parentElement.getAttribute('data-tab'));
                        })
                    });
            }

            try {
                const url = new URL(window.location.href);
                const urlTab = url.searchParams.get('tab');

                if (!urlTab) {
                    this.urlParamManager.updateParam('tab', this.activeTab);
                }
            } catch (error) {
                console.error('Error parsing URL:', error);
            }
        },
        switchTab(newTab) {
            if (this.$refs.tabsMenu) {
                this.$refs.tabsMenu.querySelectorAll('[data-tab]').forEach(e => {
                    e.classList.remove('active');
                    if (newTab === e.getAttribute('data-tab')) {
                        e.classList.add('active');
                    }
                });
            }
            this.activeTab = newTab;
            this.urlParamManager.updateParam('tab', newTab);
        },
        showTab(newTab) {
            return this.activeTab === newTab;
        }
    }
}
