/**
 * A custom component that functions like a select field but with searchable values.
 *
 * @param inputAttributes - Attributes for the visible input element.
 * @param options - An array of objects with "label" and "value" properties. Eg. [{label: 'My first label', value: 1}, {label: 'My second label', value: 2}].
 * @param required - Whether the input should be required.
 * @param isEditable - Whether the input value should be editable.
 */
export default ({inputAttributes, options, required, isEditable}) => ({
    isEditable: isEditable ?? false,
    isOpen: false,
    inputRequired: inputAttributes.required ?? false,
    search: '',
    selectedValue: '',
    inputId: inputAttributes.id ?? '',
    inputName: inputAttributes.name ?? '',
    allOptions: options ?? [],
    options: options ?? [],
    selected: {
        label: '',
        value: ''
    },
    init() {
        this.$refs.optionList.style.top = this.$refs.searchField.offsetHeight + 'px';
        this.$refs.resetButton.style.height = this.$refs.searchField.offsetHeight + 'px';

        this.$nextTick(() => {
            setTimeout(() => {
                if (this.$refs.valueField.value !== '') {
                    const option = this.options.filter((option) => {
                        return option.value == this.$refs.valueField.value;
                    })[0];
                    this.selected = option;
                    this.selectedValue = option.value;
                    this.search = option.label;

                    if (!isEditable) {
                        this.$refs.searchField.setAttribute('readonly', 'readonly');
                    }
                }
            }, 0);
        });
    },
    open() {
        this.isOpen = true;
        this.$refs.optionList.setAttribute('aria-expanded', 'true');
    },
    close() {
        this.isOpen = false;
        this.$refs.optionList.setAttribute('aria-expanded', 'false');
        if (this.isEditable) {
            this.selectedValue = this.$refs.searchField.value;
        } else if (this.selectedValue === '') {
            this.$refs.searchField.value = '';
        }
    },
    getOptions() {
        return this.options.filter((option) => {
            return option.label.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    clearAriaSelected() {
        this.$refs.optionList.querySelectorAll("li[aria-selected='true']").forEach(e => e.setAttribute('aria-selected', false));
    },
    selectValue(option) {
        this.selectedValue = option.value;
        this.selected = option;
        this.search = option.label;

        this.clearAriaSelected();
        this.close();

        this.$el.setAttribute('aria-selected', true);

        if (!this.isEditable) {
            this.$refs.searchField.setAttribute('readonly', 'readonly');
        }

        this.$nextTick(() => {
            this.$refs.valueField.dispatchEvent(new Event('change', {bubbles: true}));
        });

    },
    reset() {
        this.clearAriaSelected();
        this.selected = {
            label: '',
            value: ''
        };
        this.search = '';

        if (!this.isEditable) {
            this.$refs.searchField.removeAttribute('readonly');
        }
        this.$refs.searchField.focus();
        this.open();
    }
})