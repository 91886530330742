"use strict"
import './_prototypeExtensions.js';
import './alpineComponents/index.js'
import './_dialog.js'
import './_submenus.js'

if (typeof (window.Alpine) !== 'undefined') {
    window.Alpine.start();
}

/**
 * This script is designed to enhance the behavior of forms within elements having the `.legacy-layout` class.
 * Specifically, it targets forms that use the GET method for submission.
 * Before any such form is submitted, this script intercepts the submission event and filters out any form fields that are empty.
 * This includes not appending unchecked checkboxes or radio buttons, and excluding text inputs, select options, and textareas that have no value.
 * The goal is to prevent empty fields from being included in the submission query string, thereby streamlining the submission data and potentially improving the user experience and efficiency of data processing on the server side. Additionally, console log statements are included to provide insight into the script's operation, such as when a form submission is intercepted, which fields are being added to the submission, the construction of the final form action URL, and when the script redirects to this URL. This functionality is activated only after the DOM is fully loaded to ensure that all target forms are accessible and can be modified by the script.
 */
document.addEventListener('DOMContentLoaded', function() {
    var forms = document.querySelectorAll('.legacy-layout form');
    forms.forEach(function(form) {
        form.addEventListener('submit', function(event) {
            if (form.method.toUpperCase() === 'GET') {
                console.log("Intercepting GET form submission to filter out empty fields.");

                event.preventDefault();

                var formData = new URLSearchParams();

                for (var i = 0; i < form.elements.length; i++) {
                    var field = form.elements[i];

                    if (field.name && !field.disabled) {
                        if (field.type === 'checkbox' || field.type === 'radio') {
                            if (field.checked) {
                                formData.append(field.name, field.value);
                                console.log(`Adding checked field: ${field.name} = ${field.value}`);
                            }
                        } else {
                            if (field.value) {
                                formData.append(field.name, field.value);
                                console.log(`Adding field: ${field.name} = ${field.value}`);
                            }
                        }
                    }
                }

                var actionUrl = form.action.split('?')[0] + '?' + formData.toString();
                console.log(`Form action URL constructed: ${actionUrl}`);

                window.location.href = actionUrl;
                console.log("Redirecting to filtered URL.");
            }
        });
    });
});
