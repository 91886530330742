/**
 * This component adds toggle functionality to a password input field.
 * It will add a button that toggles the password visibility.
 * Works by adding x-data="passwordToggle({})" to an input element.
 *
 * @param toggleButtonColor
 * @returns void
 */
export default ({toggleButtonColor = '#011f6e'}) => ({
    init() {
        let $inputWrapper = document.createElement('div'),
            $toggleButton = document.createElement('button'),
            $icon = document.createElement('i');

        // Prepare the input wrapper
        $inputWrapper.classList.add('password-toggle');
        $inputWrapper.style.cssText = 'position:relative;';

        // Prepare the toggleButton
        $icon.classList.add('fa-solid', 'fa-eye');
        $icon.style.cssText = 'color: white;';
        $toggleButton.type = 'button';
        $toggleButton.css({
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 0,
            height: '100%',
            backgroundColor: toggleButtonColor,
            borderRadius: 0,
            aspectRatio: 1,
            display: 'grid',
            placeContent: 'center',
        });
        $toggleButton.appendChild($icon);
        $toggleButton.addEventListener('click', () => {
            if (this.$root.type === 'password') {
                this.$root.type = 'text';
                $icon.classList.replace('fa-eye', 'fa-eye-slash')
            } else {
                this.$root.type = 'password';
                $icon.classList.replace('fa-eye-slash', 'fa-eye')
            }
        })

        // Wrap the password field and add the toggle button
        const $passwordField = this.$root.wrap($inputWrapper);
        $passwordField.appendChild($toggleButton);


    },
})