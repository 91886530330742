export function userLocationStore() {
    return {
        userLocations: [],
        isLoading: false,
        chosenOrganizationId: null,
        chosenLocationId: null,
        fetchedUserLocations: false,
        async getUserLocations() {
            if (!this.fetchedUserLocations) {
                const userLocations = await this.fetchUserLocations();
                this.userLocations = Object.values(userLocations);
            }
            return this.userLocations;
        },
        async getUserOrganizations() {
            if (this.userLocations.length === 0) {
                const userLocations = await this.fetchUserLocations();
                this.userLocations = Object.values(userLocations);
            }
            const organizations = {};
            this.userLocations.forEach((location) => {
                if (!organizations.hasOwnProperty(location.organizationId)) {
                    organizations[location.organizationId] = location.organization;
                }
            });
            return Object.values(organizations);
        },
        getUserLocationsByOrganizationId() {
            return this.userLocations.filter(loc => loc.organizationId === this.chosenOrganizationId);
        },
        getLocationDepartments() {
            const foundLocation = this.userLocations.find(loc => loc.id === this.chosenLocationId);
            return foundLocation ? foundLocation.departments : [];
        },
        async fetchUserLocations() {
            this.isLoading = true;
            return await fetch('/ajax/user/getLoggedInUserLocations')
                .then((response) => {
                    return response.json();
                })
                .catch((e) => {
                    throw new Error(e.status);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }
}